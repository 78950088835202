import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";







const Lisst = () => {



    const [payee, setPayee] = useState([]);

    const [loaded, setLoading] = useState(false);

    
    const [userData, setUserData] = useState([]);



    //=========================Fetch Data into table start==============================//

    useEffect(() => {

        fetch(`https://bootcamp-robocentre.herokuapp.com/pipo`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          setPayee(data);
          setLoading(true);
        })
        .catch((err) => {
          throw err;
        });

    }, []);

    //=========================Fetch Data into table End==============================//




    //========================Show User Details Start=========================//

    const showDetails = (i) => {
        var go = payee[i]['_id'];

        fetch(`https://bootcamp-robocentre.herokuapp.com/payee?theId=${go}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          setUserData(data);
        })
        .catch((err) => {
          throw err;
        });
    }

    //========================Show User Details End=========================//






    //============================Script to filter table start=====================//

    const myFunction = () => {

        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName("tr");

        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
            } else {
                tr[i].style.display = "none";
            }
            }
        }
        

    }

    //============================Script to filter table end=====================//
    






    if (!loaded) {
        return (
          <>
            <div
              class="d-flex justify-content-center"
              style={{ marginTop: "18rem" }}
            >
              <div
                class="spinner-border"
                role="status"
                style={{ color: "#0000dc" }}
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </>
        );
      } else {

    
    return (

    <div className='container'>

        <div class="input-group mb-3 mt-4">
        <div class="input-group-prepend">
            <span class="input-group-text fa fa-search" id="basic-addon1" style={{fontSize: "23.5px"}}></span>
        </div>
        <input type="text" class="form-control" placeholder="Search by Id" id="myInput" onKeyUp={myFunction} />
        </div>

        <table class="table" id="myTable">
            <thead>
                <tr>
                <th scope="col">Id</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Nationality</th>
                <th scope="col">Reg. Date</th>
                <th scope="col">Reg. Time</th>
                <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {payee.map((theData, index) =>

                <tr>
                <td>{theData._id}</td>
                <td>{theData.firstname}</td>
                <td>{theData.lastname}</td>
                <td>{theData.nationality}</td>
                <td>{theData.pay_date}</td>
                <td>{theData.pay_time}</td>
                <td><Link className="fa fa-list" to="#" onClick={showDetails.bind(this, index)} data-bs-toggle="modal" data-bs-target="#infoModal" style={{textDecoration: "none"}}></Link></td>
                </tr>

                )}
            </tbody>
        </table>




        <div class="modal fade" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"><strong>ID: </strong>{userData.map(the => the._id)}</h5>
                <Link to="#" class="close" data-bs-dismiss="modal" aria-label="Close" style={{textDecoration: "none", fontSize: "25px"}}>
                    <span aria-hidden="true">&times;</span>
                </Link>
                </div>
                <div class="modal-body mx-5">
                    <div className="row">
                        <div className="col-md-6 pt-3">

                            <p><strong>First Name: </strong></p>
                            <p>{userData.map(the => the.firstname)}</p>


                            <p><strong>Last Name: </strong></p>
                            <p>{userData.map(the => the.lastname)}</p>


                            <p><strong>Telephone: </strong></p>
                            <p>{userData.map(the => the.telephone)}</p>

                            <p><strong>Email: </strong></p>
                            <p>{userData.map(the => the.email)}</p>

                            <p><strong>Nationality: </strong></p>
                            <p>{userData.map(the => the.nationality)}</p>

                            <p><strong>Gender: </strong></p>
                            <p>{userData.map(the => the.gender)}</p>

                            <p><strong>Date of Birth: </strong></p>
                            <p>{userData.map(the => the.date_of_birth)}</p>


                            <p><strong>Country of Residence: </strong></p>
                            <p>{userData.map(the => the.country_of_residence)}</p>
                        </div>


                        <div className="col-md-6 pt-3">

                            <p><strong>City: </strong></p>
                            <p>{userData.map(the => the.city)}</p>

                            <p><strong>Level of Education: </strong></p>
                            <p>{userData.map(the => the.grade)}</p>

                            <p><strong>Institution: </strong></p>
                            <p>{userData.map(the => the.institution)}</p>

                            <p><strong>Level / Year: </strong></p>
                            <p>{userData.map(the => the.year_level)}</p>

                            <p><strong>Programme: </strong></p>
                            <p>{userData.map(the => the.programme)}</p>

                            <p><strong>Parent's Name: </strong></p>
                            <p>{userData.map(the => the.parentname)}</p>

                            <p><strong>Payment Date: </strong></p>
                            <p>{userData.map(the => the.pay_date)}</p>

                            <p><strong>Payment Time: </strong></p>
                            <p>{userData.map(the => the.pay_time)}</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
            </div>
        </div>


    </div>





  )

}

}   

export default Lisst