import React, { useEffect } from "react";
import "../css/style.css";
import "../css/register.css";
import styles from "../css/Modal.module.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
//import fire from "../fire";

import step1_bot from "../images/image_step1.png";
import step2_bot from "../images/image_step2.png";
import step3_bot from "../images/image_step3.png";
import bank_img from "../images/bank-img.png";
import ccImg from "../images/credit-card.png";
import theCard from "../images/theCard.png";

const Register = () => {
  //Inititalizing firebase
  //const firebaseInstance = fire;

  var payeeData = [];
  var mainData = [];

  //const [userId, setUserId] = useState();
  //const [payee, setPayee] = useState([]);

  var toPay;

  //======================Get User Auth Id Start===============================//

  /*useEffect(() => {
    firebaseInstance.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        setUserId(uid);
      } else {
        // User is signed out
        firebaseInstance.auth().signOut();
      }
    });
  });

  console.log(userId)
  */

  //======================Get User Auth Id End===============================//

  //===============================Get Payee Data Start=================================//

  /*useEffect(() => {
    fetch(`https://bootcamp-robocentre.herokuapp.com/payee?theId=${userId}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        setPayee(data);
      })
      .catch((err) => {
        throw err;
      });
  }, [userId]);
  */
  //===============================Get Payee Data End=================================//


  

  const clearFields = () => {
    //document.getElementById("amount").value = "";
    document.getElementById("fullName2").value = "";
    document.getElementById("email2").value = "";
    document.getElementById("cardNum").value = "";
    document.getElementById("expiryMonth").value = "";
    document.getElementById("expiryYear").value = "";
    document.getElementById("theCVV").value = "";

    var loader = document.getElementById("pleaseWait2");
    loader.style.display = "none";
  };






  //===============================Do Card Payment Start==================================//

  const doCardPayment = () => {
    //var originalAmount = theMoney[0]["amount"];

    //var testAmount = document.getElementById("amount").value;
    var fullName2 = document.getElementById("fullName2").value;
    var email2 = document.getElementById("email2").value;
    var card = document.getElementById("cardNum").value;
    var expiryMonth = document.getElementById("expiryMonth").value;
    var expiryYear = document.getElementById("expiryYear").value;
    var cvv = document.getElementById("theCVV").value;

    if (
      fullName2 === "" ||
      email2 === "" ||
      card === "" ||
      expiryYear === "" ||
      expiryMonth === "" ||
      cvv === ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        type: "warning",
        icon: "warning",
        text: "Please fiil out all fields!",
      });
    } else {
      var loader = document.getElementById("pleaseWait2");
      loader.style.display = "block";

      var cardInfo = {
        data: JSON.stringify({
          card_number: card,
          cvc: cvv,
          amount: 1, //toPay
          email: email2,
          month: expiryMonth,
          year: expiryYear,
        }),
      };

      axios
        .post("https://robocentre.herokuapp.com/cardpayment", cardInfo)
        .then((response) => {
          var status = response.data.status;
          var transactionRef = response.data.reference;

          if (status === true) {
            window.open(response.data.url, "_blank");

            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: "btn btn-success",
              },
              buttonsStyling: false,
            });

            swalWithBootstrapButtons
              .fire({
                title: "Done with the payment",
                text: "Verify transaction status!",
                icon: "question",
                allowOutsideClick: false,
                confirmButtonText: "Verify",
                reverseButtons: true,
              })
              .then(() => {
                //Verify for transaction status
                clearFields();

                var verifyInfo = {
                  data: JSON.stringify({
                    reference: transactionRef,
                    userid: email2,
                  }),
                };

                axios
                  .post(
                    "https://robocentre.herokuapp.com/verification",
                    verifyInfo
                  )
                  .then((theResponse) => {
                    var today = new Date();
                    var date =
                      today.getFullYear() +
                      "-" +
                      (today.getMonth() + 1) +
                      "-" +
                      today.getDate();

                    var time = today.toLocaleString("en-US", {
                      hour: "numeric",
                      hour12: true,
                      minute: "numeric",
                    });

                    mainData.push({
                      pay_date: date,
                      pay_time: time,
                      reference: transactionRef,
                      pay_method: "online",
                      ...payeeData[0],
                    });
                    //console.log(mainData)

                    if (theResponse.data.success === true) {
                      //Payment successful

                      fetch(
                        `https://bootcamp-robocentre.herokuapp.com/register?theData=${JSON.stringify(
                          mainData[0]
                        )}`
                      )
                        .then((res) => {
                          if (res.ok) {
                            return res.json();
                          }
                        })
                        .then(
                          Swal.fire({
                            toast: true,
                            position: "top-right",
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            timer: 5000,
                            icon: "success",
                            title: "Transaction Successful",
                            text: "A receipt has been sent to your email!",
                          }).then((window.location.href = "/payment"))
                        );
                    } else {
                      Swal.fire({
                        icon: "error",
                        allowOutsideClick: false,
                        title: "Transaction Failed",
                        text: theResponse.data.error_text,
                        confirmButtonText: "Retry",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          
                          document.getElementById("fullName2").value = "";
                          document.getElementById("email2").value = "";
                          document.getElementById("cardNum").value = "";
                          document.getElementById("expiryMonth").value = "";
                          document.getElementById("expiryYear").value = "";
                          document.getElementById("theCVV").value = "";

                          var loader = document.getElementById("pleaseWait2");
                          loader.style.display = "none";

                        }
                      });
                    }
                  });
              });
          } else {
            Swal.fire({
              icon: "error",
              title: "Transaction Failed",
              allowOutsideClick: false,
              text: response.data.error_text,
              confirmButtonText: "Retry",
            }).then((result) => {
              if (result.isConfirmed) {
                
                document.getElementById("fullName2").value = "";
                document.getElementById("email2").value = "";
                document.getElementById("cardNum").value = "";
                document.getElementById("expiryMonth").value = "";
                document.getElementById("expiryYear").value = "";
                document.getElementById("theCVV").value = "";

                var loader = document.getElementById("pleaseWait2");
                loader.style.display = "none";

              }
            });
          }
        })
        .catch((error) => {
          console.log("errorMessage:", error.message);
          console.error("There was an error!", error);
        });
    }
  };

  //===============================Do Card Payment End==================================//

  //Hide parent field if Uni/basic is selected and respective amounts start
  const checkField = () => {
    var edu = document.getElementById("eduLevel");
    var edu_level = edu.options[edu.selectedIndex].text;

    if (edu_level === "University") {
      var pField2 = document.getElementById("parentField");
      pField2.style.display = "none";
      pField2.value = "N/A";

      toPay = 850;

      var uni = document.getElementById("amountUni");
      uni.style.display = "block";

      var basic2 = document.getElementById("amountBasic");
      basic2.style.display = "none";

      var uniName = document.getElementById("institutionField");
      uniName.style.display = "block";

      var theProgramme = document.getElementById("programmeField");
      theProgramme.style.display = "block";

      var theYear = document.getElementById("yearField");
      theYear.style.display = "block";
    } else {
      var pField = document.getElementById("parentField");
      pField.style.display = "block";

      toPay = 150;

      var basic = document.getElementById("amountBasic");
      basic.style.display = "block";

      var uni2 = document.getElementById("amountUni");
      uni2.style.display = "none";

      var uniName2 = document.getElementById("institutionField");
      uniName2.style.display = "none";

      var theProgramme2 = document.getElementById("programmeField");
      theProgramme2.style.display = "none";

      var theYear2 = document.getElementById("yearField");
      theYear2.style.display = "none";
    }
  };
  //Hide parent field if Uni/basic is selected and respective amounts end

  //===========================================All Next And Previous Start Here===========================================//
  //Do First Next
  const doNext1 = () => {
    var edu = document.getElementById("eduLevel");
    var edu_level = edu.options[edu.selectedIndex].text;

    var fName = document.getElementById("firstname").value;
    var lName = document.getElementById("lastname").value;
    var email = document.getElementById("email").value;
    var name_of_parent = document.getElementById("nameOfParent").value;

    var institution = document.getElementById("institutionName").value;
    var programme = document.getElementById("programme").value;
    var year = document.getElementById("year").value;

    /*
    var payeeId = payee.map((the) => the._id);

    firebaseInstance.auth().onAuthStateChanged((user) => {
      if (user) {
        //do nothing
      } else {
        Swal.fire({
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 8000,
          type: "warning",
          icon: "warning",
          text: "Please create an account or sign in to continue!",
        });
      }
    });

    if (payeeId[0] === userId) {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 8000,
        type: "warning",
        icon: "warning",
        text: "You have already made payment. Please sign in or check your mail for your receipt!",
      });
    } else 
    
    */

    if (
      edu_level === "Level of Education" ||
      fName === "" ||
      lName === "" ||
      email === ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 5000,
        type: "warning",
        icon: "warning",
        text: "Please fill out all fields to continue!",
      });
    } else if (edu_level === "Basic School" && name_of_parent === "") {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 5000,
        type: "warning",
        icon: "warning",
        text: "Parent name is required for basic level!",
      });
    } else if (edu_level === "University" && year === "") {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 5000,
        type: "warning",
        icon: "warning",
        text: "Year / Level field is required!",
      });
    } else if (edu_level === "University" && institution === "") {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 5000,
        type: "warning",
        icon: "warning",
        text: "Name of institution field is required!",
      });
    } else if (edu_level === "University" && programme === "") {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 5000,
        type: "warning",
        icon: "warning",
        text: "Programme of study field is required!",
      });
    } else {
      var step1 = document.getElementById("theForm");
      var step2 = document.getElementById("theForm2");

      step1.style.display = "none";
      step2.style.display = "block";
    }
  };

  //Do First Previous
  const doPrev1 = () => {
    var step1 = document.getElementById("theForm");
    var step2 = document.getElementById("theForm2");

    step1.style.display = "block";
    step2.style.display = "none";
  };

  //Do Second Next
  const doNext2 = () => {
    var gen = document.getElementById("theGender");
    var gender = gen.options[gen.selectedIndex].text;

    var date_of_birth = document.getElementById("dateOfBirth").value;
    var phone = document.getElementById("telephone").value;
    var nationality = document.getElementById("nationality").value;
    var residence = document.getElementById("residence").value;
    var city = document.getElementById("city").value;

    if (
      gender === "Gender" ||
      date_of_birth === "" ||
      phone === "" ||
      nationality === "" ||
      residence === "" ||
      city === ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 5000,
        type: "warning",
        icon: "warning",
        text: "Please fill out all fields to continue!",
      });
    } else {
      var step2 = document.getElementById("theForm2");
      var step3 = document.getElementById("theForm3");

      step2.style.display = "none";
      step3.style.display = "block";
    }
  };

  //Do Second Previous
  const doPrev2 = () => {
    var step2 = document.getElementById("theForm2");
    var step3 = document.getElementById("theForm3");

    step2.style.display = "block";
    step3.style.display = "none";
  };

  //===========================================All Next And Previous End Here===========================================//

  //========================Registration Step Start========================//

  const doRegistration = () => {
    var edu = document.getElementById("eduLevel");
    var edu_level = edu.options[edu.selectedIndex].text;

    var fName = document.getElementById("firstname").value;
    var lName = document.getElementById("lastname").value;
    var email = document.getElementById("email").value;
    var name_of_parent = document.getElementById("nameOfParent").value;

    var gen = document.getElementById("theGender");
    var gender = gen.options[gen.selectedIndex].text;

    var date_of_birth = document.getElementById("dateOfBirth").value;
    var phone = document.getElementById("telephone").value;
    var nationality = document.getElementById("nationality").value;
    var residence = document.getElementById("residence").value;
    var city = document.getElementById("city").value;

    var institution = document.getElementById("institutionName").value;
    var programme = document.getElementById("programme").value;
    var year = document.getElementById("year").value;

    var today = new Date();
    var date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate();

    var time = today.toLocaleString("en-US", {
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });

    var userData = {
      _id: email,
      firstname: fName,
      lastname: lName,
      parentname: name_of_parent,
      birth_of_birth: date_of_birth,
      gender: gender,
      nationality: nationality,
      telephone: phone,
      email: email,
      city: city,
      country_of_residence: residence,
      grade: edu_level,
      institution: institution,
      programme: programme,
      year_level: year,
      pay_date: date,
      pay_time: time
    };

    payeeData.push(userData);


    fetch(
      `https://bootcamp-robocentre.herokuapp.com/register?theData=${JSON.stringify(
        userData
      )}`
    )
      
    .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
        
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        });
        
        swalWithBootstrapButtons
        .fire({
          title: "Hurray!",
          text: "Your registration was successful",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonText: "Done",
          reverseButtons: true,
        })
        .then(() => {
          
          window.location.reload();
          
        })
      .catch(error => {
        if(error) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          
          swalWithBootstrapButtons
          .fire({
            title: "Ooops!",
            text: error.message,
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "Retry",
            reverseButtons: true,
          })
        }
      })

    
  };

  //========================Registration Step End========================//

  //Prevent scrolling in number fields

  useEffect(() => {
    var cardInput = document.getElementById("cardNum");
    cardInput.addEventListener("mousewheel", function (event) {
      this.blur();
    });

    var monthInput = document.getElementById("expiryMonth");
    monthInput.addEventListener("mousewheel", function (event) {
      this.blur();
    });

    var yearInput = document.getElementById("expiryYear");
    yearInput.addEventListener("mousewheel", function (event) {
      this.blur();
    });

    var cvvInput = document.getElementById("theCVV");
    cvvInput.addEventListener("mousewheel", function (event) {
      this.blur();
    });

    var phoneInput = document.getElementById("telephone");
    phoneInput.addEventListener("mousewheel", function (event) {
      this.blur();
    });
  });

  return (
    <>
      <div className="container pt-4" id="regContainer">
        <div className="multistep-container">
          <div className="multistep-form-area">
            <div className="row" id="theForm">
              <div className="col-md-6" style={{ marginLeft: "5rem" }}>
                <h3 className="pt-5" id="regText1">
                  <b>
                    Lorem ipsum dolor <br /> sit amet
                  </b>
                </h3>

                <img
                  className="pt-5"
                  src={step1_bot}
                  alt=""
                  width="300px"
                  id="formImg"
                />
              </div>

              <div className="col-md-6">
                <div className="form-box">
                  <small className="pt-3">
                    <b>Step 1:</b>
                  </small>

                  <ul className="active-button pt-3">
                    <li className="active">
                      <span className="round-btn">1</span>
                    </li>
                    <li>
                      <span className="round-btn">2</span>
                    </li>
                    <li>
                      <span className="round-btn">3</span>
                    </li>
                  </ul>

                  <form>
                    <div class="form-group pt-1">
                      <select
                        id="eduLevel"
                        class="form-select mt-3"
                        onChange={checkField}
                        required
                      >
                        <option selected disabled value="eduLevel">
                          Level of Education
                        </option>
                        <option value="basic">Basic School</option>
                        <option value="tertiary">University</option>
                      </select>
                    </div>

                    <div className="form-group pt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        placeholder="First Name"
                        required
                      />
                    </div>

                    <div className="form-group pt-4">
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        placeholder="Last Name"
                        required
                      />
                    </div>

                    <div className="form-group pt-3">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        required
                      />
                    </div>

                    <div
                      className="form-group pt-3"
                      id="parentField"
                      style={{ display: "none" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="nameOfParent"
                        placeholder="Name of Parent"
                        required
                      />
                    </div>

                    <div
                      className="form-group pt-3"
                      id="institutionField"
                      style={{ display: "none" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="institutionName"
                        placeholder="Name of Institution"
                        required
                      />
                    </div>

                    <div
                      className="form-group pt-3"
                      id="programmeField"
                      style={{ display: "none" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="programme"
                        placeholder="Programme of Study"
                        required
                      />
                    </div>

                    <div
                      className="form-group pt-3"
                      id="yearField"
                      style={{ display: "none" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="year"
                        placeholder="Year / Level"
                        required
                      />
                    </div>

                    <div className="button-row">
                      <input
                        type="button"
                        value="next"
                        className="next"
                        onClick={doNext1}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="row" id="theForm2" style={{ display: "none" }}>
              <div className="col-md-6" style={{ marginLeft: "5rem" }}>
                <h3 className="pt-5" id="regText1">
                  <b>Learner Details</b>
                </h3>

                <img
                  className="pt-5"
                  src={step2_bot}
                  alt=""
                  width="300px"
                  id="formImg"
                />
              </div>

              <div className="col-md-6">
                <div className="form-box">
                  <small className="pt-3">
                    <b>Step 2:</b>
                  </small>

                  <ul className="active-button pt-3">
                    <li className="active">
                      <span className="round-btn">1</span>
                    </li>
                    <li className="active">
                      <span className="round-btn">2</span>
                    </li>
                    <li>
                      <span className="round-btn">3</span>
                    </li>
                  </ul>

                  <form>
                    <div className="form-group pt-2">
                      <small>Date of Birth</small>
                      <input
                        type="date"
                        className="form-control"
                        id="dateOfBirth"
                        placeholder="Date of Birth"
                        required
                      />
                    </div>

                    <div class="form-group pt-1">
                      <select id="theGender" class="form-select mt-3" required>
                        <option selected disabled value="gender">
                          Gender
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>

                    <div className="form-group pt-3">
                      <input
                        type="number"
                        class="form-control"
                        id="telephone"
                        placeholder="Telephone (+233)"
                        required
                      />
                    </div>

                    <div className="form-group pt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="nationality"
                        placeholder="Nationality"
                        required
                      />
                    </div>

                    <div className="form-group pt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="residence"
                        placeholder="Country of Residence"
                        required
                      />
                    </div>

                    <div className="form-group pt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="City of Residence"
                        required
                      />
                    </div>

                    <div className="button-row">
                      <input
                        type="button"
                        value="&#8592; previous"
                        className="previous"
                        onClick={doPrev1}
                      />
                      <input
                        type="button"
                        value="next"
                        className="next"
                        onClick={doNext2}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="row" id="theForm3" style={{ display: "none" }}>
              <div className="col-md-6" style={{ marginLeft: "5rem" }}>
                <h3 className="pt-5" id="regText1">
                  <b>
                    Now proceed to <br /> make payment
                  </b>
                </h3>

                <img
                  className="pt-5"
                  src={step3_bot}
                  alt=""
                  width="300px"
                  id="formImg"
                />
              </div>

              <div className="col-md-6">
                <div className="form-box">
                  <small className="pt-3">
                    <b>Step 3:</b>
                  </small>

                  <ul className="active-button pt-3">
                    <li className="active">
                      <span className="round-btn">1</span>
                    </li>
                    <li className="active">
                      <span className="round-btn">2</span>
                    </li>
                    <li className="active">
                      <span className="round-btn">3</span>
                    </li>
                  </ul>

                  <div className="card text-white" id="theCard">
                    <h3
                      className="pt-5 text-center"
                      id="amountUni"
                      style={{ display: "none" }}
                    >
                      850 $
                    </h3>

                    <h3
                      className="pt-5 text-center"
                      id="amountBasic"
                      style={{ display: "none" }}
                    >
                      150 $
                    </h3>

                    <p className="pt-1 text-center">
                      <b>Per Learner</b>
                    </p>

                    <div className="text-center">
                      <li>Robotics kits</li>
                      <li>Course Materials</li>
                      <li>Certification</li>
                    </div>

                    <Link
                      to="#"
                      className="mt-4 mb-5 mx-5 text-center"
                      id="paynow-btn"
                      //data-bs-toggle="modal"
                      //data-bs-target="#preceedModal"
                      onClick={doRegistration}
                    >
                      Register
                    </Link>
                  </div>

                  <div className="button-row">
                    <input
                      type="button"
                      value="&#8592; previous"
                      className="previous"
                      onClick={doPrev2}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Preceed Online Modal */}
      <div
        class="modal fade"
        id="preceedModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content" style={{ padding: ".5rem 1.5rem" }}>
            <div class="modal-header" id="modal-header">
              <div class="col modal-title">
                <Link to="#" id="online-btn-active">
                  <b>Online</b>
                </Link>
                <Link
                  to="#"
                  id="bank-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#bankModal"
                >
                  <b>Bank</b>
                </Link>
              </div>

              <Link
                to="#"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontSize: "25px",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </Link>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <p id="theText">Enter Your Credit Card Details</p>

                  <img class="pt-4" src={theCard} alt="" width="300px" />
                </div>

                <div class="col-md-6">
                  <div
                    class="text-center"
                    id="pleaseWait2"
                    style={{ display: "none" }}
                  >
                    <div
                      class="spinner-border"
                      role="status"
                      id={styles["theLoader"]}
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>

                  {/*<div class="form-group pt-2">
                    <input
                      type="number"
                      class="form-control"
                      id="amount"
                      placeholder="Enter Amount"
                      autoComplete="off"
                    />
                    </div>*/}

                  <div class="form-group pt-2">
                    <input
                      type="text"
                      class="form-control"
                      id="fullName2"
                      placeholder="Full Name"
                    />
                  </div>

                  <div class="form-group pt-2">
                    <input
                      type="email"
                      class="form-control"
                      id="email2"
                      placeholder="Email"
                    />
                  </div>

                  <div class="pt-2">
                    <img src={ccImg} alt="momo" style={{ width: "50px" }} />
                  </div>

                  <div class="form-group pt-2">
                    <input
                      type="number"
                      class="form-control"
                      id="cardNum"
                      placeholder="Credit Card Number"
                      maxLength="16"
                    />
                  </div>

                  <div class="form-group pt-2">
                    <small>Expiry Month</small>
                    <input
                      type="number"
                      class="form-control"
                      id="expiryMonth"
                      placeholder="MM(example: 04)"
                      maxLength="2"
                    />
                  </div>

                  <div class="form-group pt-2">
                    <small>Expiry Year</small>
                    <input
                      type="number"
                      class="form-control"
                      id="expiryYear"
                      placeholder="YY(example: 22)"
                      maxLength="2"
                    />
                  </div>

                  <div class="form-group pt-2">
                    <small>CVV / CVV</small>
                    <input
                      type="number"
                      class="form-control"
                      id="theCVV"
                      placeholder="000"
                      maxLength="3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" id="modal-footer">
              <button type="button" class="btn" data-bs-dismiss="modal">
                cancel
              </button>
              <button
                type="button"
                class="btn"
                id="confirm-btn"
                onClick={doCardPayment}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Preceed Bank Modal */}
      <div
        class="modal fade"
        id="bankModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content" style={{ padding: ".5rem 1.5rem" }}>
            <div class="modal-header" id="modal-header">
              <div class="col modal-title">
                <Link
                  to="#"
                  id="online-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#preceedModal"
                >
                  <b>Online</b>
                </Link>
                <Link to="#" id="bank-btn-active">
                  <b>Bank</b>
                </Link>
              </div>

              <Link
                to="#"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontSize: "25px",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </Link>
            </div>
            <div class="modal-body" style={{ margin: "0rem 1rem" }}>
              <div class="row">
                <div class="col-md-6">
                  <p id="theText">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut
                  </p>

                  <img class="pt-4" src={bank_img} alt="" width="300px" />
                </div>

                <div class="col-md-6">
                  <div>
                    <p id="light-text" style={{ marginBottom: "0" }}>
                      Bank:
                    </p>
                    <p id="bold-text">GT Bank(Ghana) Limited</p>
                  </div>

                  <div>
                    <p id="light-text" style={{ marginBottom: "0" }}>
                      Account Name:
                    </p>
                    <p id="bold-text">Mingo Blox Ltd.</p>
                  </div>

                  <div>
                    <p id="light-text" style={{ marginBottom: "0" }}>
                      Bank Account No:
                    </p>
                    <p id="bold-text">201124004220</p>
                  </div>

                  <div>
                    <p id="light-text" style={{ marginBottom: "0" }}>
                      Bank Address:
                    </p>
                    <p id="bold-text">25A Castle Road, Ridge - Accra</p>
                  </div>

                  <div>
                    <p id="light-text" style={{ marginBottom: "0" }}>
                      Swift Code:
                    </p>
                    <p id="bold-text">GTBIGHAC</p>
                  </div>

                  <div>
                    <p id="light-text" style={{ marginBottom: "0" }}>
                      Branch Code:
                    </p>
                    <p id="bold-text">201</p>
                  </div>

                  <div>
                    <p id="light-text" style={{ marginBottom: "0" }}>
                      Sort Code:
                    </p>
                    <p id="bold-text">230101</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" id="modal-footer">
              <button
                type="button"
                class="btn"
                data-bs-dismiss="modal"
                id="confirm-btn"
              >
                Finish
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
