import React, { useEffect, useState } from "react";
import "../css/style.css";
import { Link } from "react-router-dom";
import "../css/register.css";
import logo from '../images/mingo-logo.png';
import logo_white from '../images/logo-white.png';
import fire from '../fire';
import Swal from "sweetalert2";

const Payment = () => {

  //Inititalizing firebase
  const firebaseInstance = fire;

  const [payee, setPayee] = useState([]);

  const [loaded, setLoading] = useState(false);








  //======================Get User Auth Id Start===============================//

  useEffect(() => {
    firebaseInstance.auth().onAuthStateChanged((user) => {
      if (user) {
  
        var userId = user.uid;
  
        fetch(`https://bootcamp-robocentre.herokuapp.com/payee?theId=${userId}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          setPayee(data);
          setLoading(true);

          if (data.length === 0)
          {
            Swal.fire({
              title: 'Oops!',
              text: "Record of payment does not exist for this account!",
              icon: 'warning',
              showCancelButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Make Payment'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/#regHere"
              }
            })
          }
        })
        .catch((err) => {
          throw err;
        });
        
      } else {
        // User is signed out
        firebaseInstance.auth().signOut()
        .then(
          window.location.href = '/'
        )
      }
    });
  }, [firebaseInstance])

  //======================Get User Auth Id End===============================//






  const doSignOut = () => {

    firebaseInstance.auth().signOut()
    .then(() => {

      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        type: "success",
        icon: "success",
        text: "Signed Out!",
      });

    })
    .then
    (
      window.location.href = "/"
    )

  }










  //==================== Save PDF Start ===================//

  const doSave = () => {
    //var doc = new jsPDF('portrait', 'pt', 'a4', true);

    window.print();

    window.onafterprint = function () {
      window.location.href = "/";
    };

    //doc.save("MingoBlox Bootcamp Receipt.pdf");
  };

  //==================== Save PDF End ===================//




  if (!loaded) {
    return (
      <>
        <div
          class="d-flex justify-content-center"
          style={{ marginTop: "18rem" }}
        >
          <div
            class="spinner-border"
            role="status"
            style={{ color: "#0000dc" }}
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </>
    );
  } else {
    return (

      <>
      <body id="theBody">



                  <nav className="navbar navbar-dark navbar-expand-sm bg-white fixed-top">
                    <div className="container">
                        
                        <img class="navbar-brand" id="theLogo" src={logo} alt="logo" />

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                            <span className="fa fa-bars" style={{color: "#0000dc"}}></span>
                        </button>


                        <div id="navbarCollapse" className="collapse navbar-collapse">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a href="#footer" className="nav-link active">
                                        CONTACT
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" className="nav-link active" id="register-btn" onClick={doSignOut}>
                                        SIGN OUT
                                    </Link>
                                </li>

                                <li className='nav-item pt-1'>

                                <div id="google_translate_element"></div>

                                </li>
                            </ul>
                        </div>

                    </div>
                </nav>


        <br /> <br />

        {/* Receipt Modal */}


        <div class="card" id="receiptCard">
          <div class="container pt-4" id="theCont">
            <div class="text-center">
              <i class="fa fa-check" id="icon"></i>
            </div>
            <h4 class="pt-3 text-center" id="theHeading">
              Payment Successful
            </h4>

            <p class="pt-2 text-center">
              <b>Receipt ID: {payee.map((the) => the.reference)}</b>
            </p>

            <hr class="pt-4" id="hr" />

            <div class="row">
              <div class="col-md-8">
                <p id="light-text" style={{ marginBottom: "0" }}>
                  Date
                </p>
                <p>{payee.map((the) => the.pay_date)}</p>
              </div>

              <div class="col-md-4">
                <p id="light-text" style={{ marginBottom: "0" }}>
                  Date
                </p>
                <p>{payee.map((the) => the.pay_time)}</p>
              </div>
            </div>

            <hr />

            <div>
              <p id="light-text" style={{ marginBottom: "0" }}>
                Received from
              </p>
              <p>
                {payee.map((the) => the.firstname) +
                  " " +
                  payee.map((the) => the.lastname)}
              </p>
            </div>

            <hr />

            <div>
              <p id="light-text" style={{ marginBottom: "0" }}>
                For the payment of
              </p>
              <p>Robotics and STEM BootCamp</p>
            </div>

            <hr />

            <div class="row pt-3">
              <div class="col-md-8">
                <p id="light-text" style={{ marginBottom: "0" }}>
                  Total amount paid
                </p>
                <p style={{ fontSize: "25px" }}>
                  <b>$ 1,000.00</b>
                </p>
              </div>

              <div class="col-md-4 pt-4">
                <Link
                  to="#"
                  id="save-receipt"
                  style={{ textDecoration: "none" }}
                  onClick={doSave}
                >
                  Save Receipt
                </Link>
              </div>
            </div>
          </div>
        </div>


        <br /> <br />




                  <div className='pt-5 pb-3' style={{backgroundColor: "#000"}} id="footer">
                    <div className='container' style={{paddingLeft: "8rem", paddingRight: "8rem"}}>
                            <div className='row pt-5 pb-5 text-white'>
                                <div className='col-md-4 pt-3'>
                                    <img src={logo_white} alt="white" />

                                    <p className='pt-5'>Copyright  Mingo Blox LLC <br />
                                        &#169; 2021
                                    </p>
                                </div>

                                <div className='col-md-4 pt-3'>
                                    <h5>Contact</h5>
                                    <hr style={{width: "180px"}} />

                                    <p>
                                        <i className='fa fa-phone'></i> 030 263 3211 / 059 478 7636 
                                    </p>

                                    <br />

                                    <p>
                                        <i className='fa fa-map-marker'></i> Presidential floor, Mövenpick Ambassador Hotel Independence Avenue, Ridge, Accra
                                    </p>

                                    <br />

                                    <p>
                                        <i className='fa fa-envelope'></i> info@mingoroboticscentre.com
                                    </p>
                                </div>

                                <div className='col-md-4 pt-3'>
                                    <h5>Get in touch</h5>
                                    <hr style={{width: "250px"}} />
                                    
                                    <div className='row'>
                                        <div className='col-md-6' style={{marginBottom: "2rem"}}>
                                            <a href="https://twitter.com/MingoBlox" target="_blank" rel="noreferrer" id="social-media"><i className='fab fa-twitter'></i></a>
                                            <a href="https://web.facebook.com/MingoBlox" target="_blank" rel="noreferrer" id="social-media"><i className='fab fa-facebook-f'></i></a>
                                        </div>
                                        
                                        <div className='col-md-6'>  
                                            <Link to="#"  id="social-media"><i className='fab fa-instagram'></i></Link>
                                            <a href="https://www.youtube.com/channel/UCjUNuTXIyNIeHpRKag0H_NA" target="_blank" rel="noreferrer" id="social-media"><i className='fab fa-youtube'></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                  </div>


      </body>
      </>
    );
  }
};

export default Payment;
