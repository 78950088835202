import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './pages/Home';
import Payment from './pages/Payment';
import Registered from './pages/Registered';
import Lisst from './pages/Lisst';

function App() {
  return (
    
    <Router>
      <Route exact path='/' component={Home} />
      <Route exact path='/payment' component={Payment} />
      <Route exact path='/registered' component={Registered} />
      <Route exact path='/lisst' component={Lisst} />
    </Router>

  );
}

export default App;
