import React from 'react';
import { Link } from 'react-router-dom';
import '../css/style.css';
import logo from '../images/mingo-logo.png';
import slider1 from '../images/slider-1a.png';
import slider2 from '../images/slider-2.jpg';
import slider3 from '../images/slider-3.jpg';
import calendar from '../images/calendar.png';
import timer from '../images/timer.png';
import location from '../images/location.png';
import theRobot from '../images/robot-img.png';
import learnImg from '../images/learn.png';
import buildImg from '../images/build.png';
import hireImg from '../images/hire.png';
import logo_white from '../images/logo-white.png';
import bot_white from '../images/bot_white.png';
import electronics from '../images/electronics.png';
import image_coding from '../images/image_coding.png';
import signinImg from '../images/signin.png';
import signupImg from '../images/signup.png';
import bgImg2 from '../images/image_BG.jpg';
import Swal from "sweetalert2";
import fire from '../fire';

import s1 from '../images/s1.png';
import s2 from '../images/s2.png';
import s3 from '../images/s3.png';
import s6 from '../images/s6.png';
import s7 from '../images/s7.png';
import s8 from '../images/s8.png';
import s9 from '../images/s9.png';
//import s10 from '../images/s10.png';
//import s11 from '../images/s11.png';
//import s12 from '../images/s12.png';
//import s13 from '../images/s13.png';
import agitel from '../images/agitel.jpeg';
import hec from '../images/hec.jpeg';
import igb from '../images/igb.jpeg';
import pigier from '../images/pigier.jpeg';
import Register from '../components/Register';
import { useEffect } from 'react/cjs/react.production.min';
//import { useState } from 'react/cjs/react.development';







const Home = () => {


    //Inititalizing firebase
    const firebaseInstance = fire;

    //const [userId, setUserId] = useState();

















    const clearFields = () => {
        document.getElementById('thatMail').value = '';
        document.getElementById('thatName').value = '';
    }



    const doSubscribe = () => {

        var email = document.getElementById('thatMail').value;
        var name = document.getElementById('thatName').value;

        if (email === '' || name === '')
        {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                type: "warning",
                icon: "warning",
                text: "Please fiil out all fields!",
            });
        }
        else
        {

            fetch(`https://bootcamp-robocentre.herokuapp.com/subscribe?theEmail=${email}&theName=${name}`)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then(

                Swal.fire({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    icon: "success",
                    text: "Subscription successful!",
                })
            )
            .then(
                clearFields()
            );
  
        }

    }




    const doSignIn = () => {

        var signInEmail = document.getElementById('user_email').value;
        var signInPassword = document.getElementById('user_password').value;

        if (signInEmail === "")
        {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 4000,
                type: "warning",
                icon: "warning",
                text: "Please enter your email!",
            })
        }
        else if (signInPassword === "")
        {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 4000,
                type: "warning",
                icon: "warning",
                text: "Please enter your password!",
            })
        }
        else 
        {
            firebaseInstance.auth().signInWithEmailAndPassword(signInEmail, signInPassword)
            .then((userCredential) => {
                // Signed in 
                //var user = userCredential.user.uid;
                //setUserId(user);

                Swal.fire({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 4000,
                    type: "success",
                    icon: "success",
                    text: "Signed in, welcome back!",
                })

            })
            .then(() => {

                //Redirect to paid page
                window.location.href = "/payment"
                document.getElementById('user_email').value = ""
                document.getElementById('user_password').value = ""
                
            })
            .catch((error) => {
                var errorMessage = error.message;
                Swal.fire({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 4000,
                    type: "error",
                    icon: "error",
                    text: errorMessage,
                })
            });
        }

    }





    const doSignUp = () => {

        var signUpEmail = document.getElementById('signup_email').value;
        var confirmEmail = document.getElementById('confirm_email').value;
        var signUpPassword = document.getElementById('signup_password').value;

        if (signUpEmail !== confirmEmail)
        {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 4000,
                type: "warning",
                icon: "warning",
                text: "Emails do not match!",
            })
        }
        else if (signUpEmail === "" || confirmEmail === "" || signUpPassword === "")
        {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 4000,
                type: "warning",
                icon: "warning",
                text: "Please fill out all fields provided!",
            })
        }
        else
        {
            firebaseInstance.auth().createUserWithEmailAndPassword(signUpEmail, signUpPassword)
            .then((userCredential) => {
                // Signed in 
                //var user = userCredential.user.uid;
                //setUserId(user);

                Swal.fire({
                    title: 'Welcome!',
                    text: "Record of payment does not exist for this account! Proceed to register and make payment",
                    icon: 'warning',
                    showCancelButton: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Make Payment'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.href = "/#regHere"
                    }
                })
                

            })
            .then(() => {

                //Redirect to paid page
                window.location.href = "/#regHere"

                document.getElementById('signup_email').value = ""
                document.getElementById('confirm_email').value = ""
                document.getElementById('signup_password').value = ""
                
            })
            .catch((error) => {
                var errorMessage = error.message;
                Swal.fire({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 4000,
                    type: "error",
                    icon: "error",
                    text: errorMessage,
                })
            });
        }

    }







    const doReset = () => {

        var resetEmail = document.getElementById('reset_email').value;

        if (resetEmail === "")
        {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 4000,
                type: "warning",
                icon: "warning",
                text: "Please enter your email!",
            })
        }
        else
        {
            firebaseInstance.auth().sendPasswordResetEmail(resetEmail)
            .then(() => {
                
                Swal.fire({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 5000,
                    type: "success",
                    icon: "success",
                    text: "A password reset link has been sent to your email. Please check your mail to continue!",
                })

            })
            .then(

                //Redirect to paid page
                document.getElementById('reset_email').value = ""
                
            )
            .catch((error) => {
                var errorMessage = error.message;
                Swal.fire({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 4000,
                    type: "error",
                    icon: "error",
                    text: errorMessage,
                })
            });
        }

    }




  
    

    return (
        

        <>
            
            <body id="theBody">

                <nav className="navbar navbar-dark navbar-expand-sm bg-white fixed-top">
                    <div className="container">
                        <a href="#home" className="navbar-brand">
                            <img id="theLogo" src={logo} alt="logo" />
                        </a>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                            <span className="fa fa-bars" style={{color: "#0000dc"}}></span>
                        </button>


                        <div id="navbarCollapse" className="collapse navbar-collapse">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a href="#program" className="nav-link active">
                                        THE PROGRAM
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#modules" className="nav-link active">
                                        MODULES
                                    </a>
                                </li>
                                {/*<li className="nav-item">
                                    <a href="#cost" className="nav-link active">
                                        COST
                                    </a>
                                </li>*/}
                                <li className="nav-item">
                                    <a href="#footer" className="nav-link active">
                                        CONTACT
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <Link to="#regHere" className="nav-link active" id="register-btn" /*data-bs-toggle="modal" data-bs-target="#signinModal"*/>
                                        Register
                                    </Link>
                                </li>

                                <li className='nav-item pt-1'>

                                
                                <div id="google_translate_element"></div>

                                </li>
                            </ul>
                        </div>

                    </div>
                </nav>


                {/*<div id="home">
                    <img src={bgImg} alt="bgImg" id="theBG-Image"/>

                    <div className='container'>
                        <p id="theTopText" className='text-white'>I am a learner of the digital age</p>

                        <h2 className='h2' id="robotics">ROBOTICS</h2>
                        <h2 className='h2' id="stem">AND STEM</h2>
                        <h2 className='h2' id="bootcamp">BOOTCAMP</h2>
                    </div>

                    <a href="#regHere" id="main-btn">Register Now</a>
                </div>*/}


                <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="10000">
                        <img src={slider1} class="d-block w-100" alt="..." />
                        <div id="carousel-caption1" class="carousel-caption">
                            <div>
                                <h2 class="mb-5" id="bootcamp">ROBOTICS <br /> AND STEM <br /> BOOTCAMP</h2>

                                <a href="#regHere" id="reg-btn">Register Now</a>
                            </div>
                        </div>

                        <p id="learner-text">I am a learner of the digital age</p>

                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                        <img src={slider2} class="d-block w-100" alt="..." />
                        <div id="carousel-caption2" class="carousel-caption">
                            <div>
                                <h2 class="mb-5 text-white" id="bootcamp2">THE <br /> REVOLUTION <br /> OF ROBOTS</h2>

                                <p id="slider2Text" class="mb-5 text-white">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy <br /> 
                                    eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam <br /> 
                                    voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
                                </p>

                                <Link to="#" id="reg-btn2" data-bs-toggle="modal" data-bs-target="#youtubeVid">Watch Video</Link>
                            </div>
                        </div>
                        </div>



                        <div class="carousel-item" data-bs-interval="10000">
                        <img src={slider3} class="d-block w-100" alt="..." />
                        <div id="carousel-caption3" class="carousel-caption">
                            <div>
                                <h2 class="mb-5 text-dark" id="bootcamp2">THE <br /> REVOLUTION <br /> OF ROBOTS</h2>

                                <p id="slider2Text" class="mb-5 text-dark">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy <br /> 
                                    eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam <br /> 
                                    voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
                                </p>

                                <a href="#regHere" id="reg-btn3">Register Now</a>
                            </div>
                        </div>
                        </div>

                        
                        
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>






                <div className='container pt-5' id="sponsors" style={{paddingLeft: "10rem", paddingRight: "10rem"}}>
                    <section className='pt-4' id="main">

                        <ul id="autoWidth" class="cs-hidden">
                            <li class="item-a">
                                <div class="showcase-box">
                                    <a href="https://edition.cnn.com/videos/world/2020/01/16/ghana-science-epilepsy-inside-africa.cnn?fbclid=IwAR38KFOSHXWqPJOIwlXVsnJ_aUoqFuzzSA8TdgMXsDXp7rswctOCCOSKQZU" rel="noreferrer" target="_blank">
                                        <img src={s1} alt="" />
                                    </a>
                                </div>
                            </li>

                            <li class="item-b">
                                <div class="showcase-box">
                                    <a href="https://www.newtimes.co.rw/section/read/199862" rel="noreferrer" target="_blank">
                                        <img src={s2} alt="" />
                                    </a>
                                </div>
                            </li>

                            <li class="item-c">
                                <div class="showcase-box">
                                    <a href="https://www.aljazeera.com/program/innovate-africa/2014/12/14/tech-teaching" rel="noreferrer" target="_blank">
                                        <img src={s3} alt="" />
                                    </a>
                                </div>
                            </li>

                            <li class="item-e">
                                <div class="showcase-box">
                                    <a href="https://www.theguardian.com/world/2013/nov/07/ghana-accra-technology-startup-robot" rel="noreferrer" target="_blank">
                                        <img src={s6} alt="" />
                                    </a>
                                </div>
                            </li>

                            <li class="item-e">
                                <div class="showcase-box">
                                    <a href="https://www.cnbcafrica.com/2019/this-is-how-technology-can-accelerate-universal-healthcare-in-africa/" rel="noreferrer" target="_blank">
                                        <img src={s7} alt="" />
                                    </a>
                                </div>
                            </li>

                            <li class="item-e">
                                <div class="showcase-box">
                                    <a href="https://www.orfonline.org/wp-content/uploads/2020/01/KR_Book-27-01-2020_compressed.pdf" rel="noreferrer" target="_blank">
                                        <img src={s8} alt="" />
                                    </a>
                                </div>
                            </li>

                            <li class="item-e">
                                <a href="https://drpcngr.org/report-of-the-africa-health-agenda-international-conference-2019" rel="noreferrer" target="_blank">
                                    <img src={s9} alt="" />
                                </a>
                            </li>
                        </ul>
                        
                    </section>
                </div>







                <div className='container pt-5 px-5' id="program">
                    <div className='row pt-5'>
                        <div className='col-md-6 pt-3 text-center'>
                            <img id="roundRobot" src={theRobot} alt="robot" width="350px"/>
                        </div>

                        <div className='col-md-6 pt-3'>
                            <h4 id="theProgram" style={{fontWeight: "bold"}}>The Program</h4>

                            <p className='pt-3' id="theText">
                                Introducing the Mingo robot to the next generation of scientists,<br /> 
                                engineers, technicians, inventors and entrepreneurs in Côte d'Ivoire!
                            </p>

                            <p className='pt-5' id="details"><span className='px-4'><img src={calendar} alt="cal"  width="25px" /></span> 
                                February 09, 13 and 14, 2022
                            </p>

                            <p className='pt-2' id="details"><span className='px-4'><img src={timer} alt="cal"  width="25px" /></span> 
                                09:00 GMT - 15:00 GMT (every day)
                            </p>

                            <p className='pt-2' id="details"><span className='px-4'><img src={location} alt="cal"  width="22px" /></span> 
                                Palm club hotel
                            </p>
                        </div>
                    </div>
                </div>




                <div className='container pt-5 px-5 mb-5'>
                    <h4 className='text-center pt-5' id="theProgram" style={{fontWeight: "bold"}}>Why Sign Up?</h4>

                    <p className='text-center pt-3' id="theText">
                        Completely new to IT or engineering and not sure where to start? We will give you a head start to excel <br /> 
                        academically and in your future career.
                    </p>

                    <div className='row pt-5'>
                        <div className='text-center col-md-4 mb-3'>
                            <div className='div'  id="whyCard">
                                <img className='pt-3' src={learnImg} alt="learn"  width="50px" />

                                <h5 className='pt-4'><b>Learning a skill</b></h5>

                                <p className='pt-4' id="theText2">
                                    Build your technical skills, build confidence in your abilities, and better prepare yourself to study computer science, engineering, or closely related fields.
                                </p>
                            </div>
                        </div>

                        <div className='text-center col-md-4 mb-3'>
                            <div className='div' id="whyCard">
                                <img className='pt-3' src={buildImg} alt="learn"  width="70px" />

                                <h5 className='pt-4'><b>Build a portfolio</b></h5>

                                <p className='pt-4' id="theText2">
                                    Start building important personal networks and friendships with a diverse group of students who share your passion for IT, engineering, or closely related fields.
                                </p>
                            </div>
                        </div>

                        <div className='text-center col-md-4 mb-3'>
                            <div className='div' id="whyCard">
                                <img className='pt-3' src={hireImg} alt="learn"  width="40px" />

                                <h5 className='pt-4'><b>Get Hired</b></h5>

                                <p className='pt-4' id="theText2">
                                    Gain visibility and insight into intership programs and technical career opportunities.
                                </p> <br /> <br />
                            </div>
                        </div>
                    </div>

                    <div className='pt-5 text-center'>
                        <a href="#regHere" id="register-btn2">Register Now</a>
                    </div>
                </div>



                <div className='pt-5' id="modules">
                    <img src={bgImg2} alt="bg2" style={{objectFit: "cover", width: "100%", height: "400px"}}/>

                    <div className='container pt-3' style={{paddingLeft: "12rem", paddingRight: "12rem"}} id="modulesContainer">
                        <div className='row pt-5 text-white text-center' id="theModule">
                            <h4 className='modTitle' id="theProgram" style={{fontWeight: "bold"}}>Modules</h4>

                            <div className='col-md-4 pt-5'>
                                <img src={bot_white} alt="robot" id="theRobotWhite" />

                                <p className='pt-4' id="modText">Robot Adventures</p>
                            </div>

                            <div className='col-md-4 pt-5'>
                                <img src={electronics} alt="electro" id="theRobotWhite2" />

                                <p className='pt-4' id="modText2">Digital Electronics and Coding</p>
                            </div>

                            <div className='col-md-4 pt-5'>
                                <img className='pt-3' src={image_coding} alt="coding" id="theRobotWhite2" />

                                <p className='pt-4' id="modText2">Coding for Robot</p>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='container pt-3 px-5' id="regHere">
                    <h4 className='text-center' id="theProgram" style={{fontWeight: "bold"}}>Register in 3 easy steps</h4>

                    <Register />

                </div>

                <br /> <br /> <br /> <br /> <br /> <br /> <br />




                <div className='container pt-5 px-5' id="regHere">
                    <h4 className='text-center' id="theProgram" style={{fontWeight: "bold"}}>Our partner Institutions</h4>

                    <div class="text-center pt-2">
                        <img class="pt-2 px-4" src={agitel} alt="" width="170px" />
                        <img class="pt-2 px-4" src={hec} alt="" width="150px" />
                        <img class="pt-2 px-4" src={igb} alt="" width="150px" />
                        <img class="pt-2 px-4" src={pigier} alt="" width="130px" />
                    </div>

                </div>


                


                <div className='pt-5 px-5' style={{backgroundColor: "#F3F3FF"}} id="cost">
                    <div id="costContainer" className='container' style={{paddingLeft: "15rem", paddingRight: "15rem"}}>
                        <div className='row pt-2'>
                            <p className='text-center'>Hi mingoblox,<br />I want to get Tips, News on Courses</p>

                            <div className='col-md-4' style={{paddingRight: "0"}} id="subsName">
                                <div class="input-group">
                                    <input type="text" id="thatName" class="form-control rounded-0" placeholder="Name" />
                                </div>
                            </div>

                            <div className='col-md-8' id="subsMail" style={{paddingLeft: "0"}}>
                                <div class="input-group">
                                    <input type="email" id="thatMail" class="form-control rounded-0" placeholder="Email" />
                                    <div class="input-group-append">
                                        <button class="btn text-white rounded-0" id="subsBtn" type="button" style={{backgroundColor: "#0000dc"}} onClick={doSubscribe}>Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <br /> <br /> <br />
                </div>

                


                <div className='pt-5 pb-3' style={{backgroundColor: "#000"}} id="footer">
                    <div className='container' style={{paddingLeft: "8rem", paddingRight: "8rem"}}>
                            <div className='row pt-5 pb-5 text-white'>
                                <div className='col-md-4 pt-3'>
                                    <img src={logo_white} alt="white" />

                                    <p className='pt-5'>Copyright  Mingo Blox LLC <br />
                                        &#169; 2021
                                    </p>
                                </div>

                                <div className='col-md-4 pt-3'>
                                    <h5>Contact</h5>
                                    <hr style={{width: "180px"}} />

                                    <p>
                                        <i className='fa fa-phone'></i> 030 263 3211 / 059 478 7636 
                                    </p>

                                    <br />

                                    <p>
                                        <i className='fa fa-map-marker'></i> Presidential floor, Mövenpick Ambassador Hotel Independence Avenue, Ridge, Accra
                                    </p>

                                    <br />

                                    <p>
                                        <i className='fa fa-envelope'></i> info@mingoroboticscentre.com
                                    </p>
                                </div>

                                <div className='col-md-4 pt-3'>
                                    <h5>Get in touch</h5>
                                    <hr style={{width: "250px"}} />
                                    
                                    <div className='row'>
                                        <div className='col-md-6' style={{marginBottom: "2rem"}}>
                                            <a href="https://twitter.com/MingoBlox" target="_blank" rel="noreferrer" id="social-media"><i className='fab fa-twitter'></i></a>
                                            <a href="https://web.facebook.com/MingoBlox" target="_blank" rel="noreferrer" id="social-media"><i className='fab fa-facebook-f'></i></a>
                                        </div>
                                        
                                        <div className='col-md-6'>  
                                            <Link to="#"  id="social-media"><i className='fab fa-instagram'></i></Link>
                                            <a href="https://www.youtube.com/channel/UCjUNuTXIyNIeHpRKag0H_NA" target="_blank" rel="noreferrer" id="social-media"><i className='fab fa-youtube'></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>







                







                {/* Youtube Modal */}

                <div class="modal fade" id="youtubeVid" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">MingoBlox</h5>
                            <Link tp="#" type="button" class="close" data-bs-dismiss="modal" aria-label="Close" style={{textDecoration: "none"}}>
                            <span aria-hidden="true">&times;</span>
                            </Link>
                        </div>
                        <div class="modal-body">
                            <iframe width="100%" height="500" src="https://www.youtube.com/embed/8KndQ0pkBY0?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                        </div>
                        </div>
                    </div>
                </div>










                {/* Sign In Modal */}
                <div class="modal fade" id="signinModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="container modal-content">
                            <div class="modal-header border-0">
                                <h5 class="modal-title" id="theModalTitle">Sign in</h5>
                                <Link to="#" class="close text-dark" data-bs-dismiss="modal" aria-label="Close" style={{textDecoration: "none", fontSize: "30px"}}>
                                <span aria-hidden="true">&times;</span>
                                </Link>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-5">
                                        <img src={signinImg} alt="" />
                                    </div>

                                    <div class="col-md-7">
                                        <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="user_email"
                                            placeholder="Email"
                                            required
                                        />
                                        </div>

                                        <div className="form-group pt-3">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="user_password"
                                            placeholder="Password"
                                            required
                                        />
                                        </div>

                                        <p class="pt-3">Forgot password? <Link to="#" data-bs-toggle="modal" data-bs-target="#pwdResetModal" style={{color: "#24b4eb", textDecoration: "none"}}>Reset Password</Link></p>

                                        <p class="pt-0">Don't have an account? <Link to="#" data-bs-toggle="modal" data-bs-target="#signupModal" style={{color: "#24b4eb", textDecoration: "none"}}>Sign Up</Link></p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer border-0">
                                <Link to="#" class="btn" id="signin-btn" onClick={doSignIn}>Sign In</Link>
                            </div>
                        </div>
                    </div>
                </div>












                {/* Sign Up Modal */}
                <div class="modal fade" id="signupModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="container modal-content">
                            <div class="modal-header border-0">
                                <h5 class="modal-title" id="theModalTitle">Sign up</h5>
                                <Link to="#" class="close text-dark" data-bs-dismiss="modal" aria-label="Close" style={{textDecoration: "none", fontSize: "30px"}}>
                                <span aria-hidden="true">&times;</span>
                                </Link>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-5">
                                        <img src={signupImg} alt="" />
                                    </div>

                                    <div class="col-md-7">
                                        <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="signup_email"
                                            placeholder="Email"
                                            required
                                        />
                                        </div>

                                        <div className="form-group pt-3">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="confirm_email"
                                            placeholder="Confirm Email"
                                            required
                                        />
                                        </div>

                                        <div className="form-group pt-3">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="signup_password"
                                            placeholder="Create Password"
                                            required
                                        />
                                        </div>

                                        <p class="pt-3">Already have an account? <Link to="#" data-bs-toggle="modal" data-bs-target="#signinModal" style={{color: "#24b4eb", textDecoration: "none"}}>Sign In</Link></p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer border-0">
                                <Link to="#" class="btn" id="signin-btn" onClick={doSignUp}>Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>












                 {/* Password Reset Modal */}
                 <div class="modal fade" id="pwdResetModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="container modal-content">
                            <div class="modal-header border-0">
                                <h5 class="modal-title" id="theModalTitle">Password Reset</h5>
                                <Link to="#" class="close text-dark" data-bs-dismiss="modal" aria-label="Close" style={{textDecoration: "none", fontSize: "30px"}}>
                                <span aria-hidden="true">&times;</span>
                                </Link>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-5">
                                        <img src={signinImg} alt="" />
                                    </div>

                                    <div class="col-md-7">
                                        <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="reset_email"
                                            placeholder="Enter Your Email"
                                            required
                                        />
                                        </div>

                                        <p class="pt-3">Don't have an account? <Link to="#" data-bs-toggle="modal" data-bs-target="#signupModal" style={{color: "#24b4eb", textDecoration: "none"}}>Sign Up</Link></p>

                                        
                                        <Link to="#" class="btn" id="signin-btn" onClick={doReset}>Submit</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>








            </body>

        </>

    )
    
}

export default Home
