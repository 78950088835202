import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyAgZp2yjVyIXM6_SK_e9t8M-2TBTBryQZY",
  authDomain: "robocentre-5843f.firebaseapp.com",
  databaseURL: "https://robocentre-5843f-default-rtdb.firebaseio.com",
  projectId: "robocentre-5843f",
  storageBucket: "robocentre-5843f.appspot.com",
  messagingSenderId: "1003566985607",
  appId: "1:1003566985607:web:c482446c4d23d1e28fe322"
};

// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);

export default fire;